<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="is-label-14px-normal">
        <div class="columns is-multiline">
          <div class="column is-4 has-text-weight-bold">
            Name
            <span class="has-text-danger">*</span>
          </div>
          <div class="column is-8 mt-0">
            <ValidationProvider
              name="Time Off Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input
                v-model="data.name"
                placeholder="Time Off Name"
                class="custom-form-height"
              ></b-input>
              <div class="has-text-danger">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="column is-4 has-text-weight-bold">
            Date
            <span class="has-text-danger">*</span>
          </div>
          <div class="column is-8">
            <ValidationProvider
              name="Date"
              rules="required"
              v-slot="{ errors }"
            >
              <b-datepicker
                v-model="data.date"
                placeholder="Date"
                trap-focus
                editable
              ></b-datepicker>
              <div class="has-text-danger">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="column is-4 has-text-weight-bold">Is Paid</div>
          <div class="column is-8">
            <b-switch v-model="data.isPaid" true-value="1" false-value="0">
              {{ data.isPaid == '1' ? 'Yes' : 'No' }}
            </b-switch>
          </div>
          <div class="column is-6" v-if="formMode === 'edit'">
            <b-button
              type="is-primary"
              outlined
              expanded
              @click="onDeleteClick"
              :loading="isLoadingDelete"
              class="ml-0"
            >
              Delete
            </b-button>
          </div>
          <div class="column is-6 is-align-items-end">
            <b-button expanded class="ml-0" native-type="submit" type="is-primary">Save</b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'
import { mapMutations } from 'vuex'

export default {
  props: {
    formMode: String,
    initialData: Object,
    leaveType: String,
  },
  watch: {
    initialData(val) {
      if (this.formMode === 'edit') {
        // console.log(this.data.id)
        val.date = new Date(val.date)
        this.data = { ...val }
      } else {
        this.clearState()
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingDelete: false,
      data: {
        id: null,
        name: '',
        date: null,
        isPaid: 0,
      },
    }
  },
  methods: {
    ...mapMutations({
      pushGlobalTimeoff: 'settings/pushGlobalTimeoff',
      updateGlobalTimeoff: 'settings/updateGlobalTimeoff',
      deleteGlobalTimeoff: 'settings/deleteGlobalTimeoff',
    }),
    async onDeleteClick() {
      this.isLoadingDelete = true
      
      try {
        let response = await ApiService.delete(
          `/api/space-roketin/global-time-off/${this.data.id}/delete`
        )

        this.deleteGlobalTimeoff(this.data.id)
        showToast(response.data.message, 'is-success', 'is-bottom')
      } catch (err) {
        showToast(err.response.data.message, 'is-danger', 'is-bottom')
      }
      this.clearState()
      this.isLoadingDelete = false
      this.$emit('close-modal')
    },
    async submit() {
      console.log('Bebas')
      
      try {
        if (this.formMode === 'edit') {
          let response = await ApiService.put(
            `/api/space-roketin/global-time-off/${this.data.id}/update`,
            this.data
          )
          let updatedLeave = response.data
          showToast('Update Success', 'is-success', 'is-bottom')
          this.updateGlobalTimeoff(updatedLeave)
        } else {
          let response = await ApiService.post(
            '/api/space-roketin/global-time-off',
            this.data
          )
          console.log('Response = ', response.data)
          let newLeave = response.data
          this.pushGlobalTimeoff(newLeave)
          showToast('Add Global Time Off Success', 'is-success', 'is-bottom')
        }
      } catch (err) {
        showToast(err.response.data.message, 'is-danger', 'is-bottom')
      }
      this.clearState()
      this.$emit('close-modal')
    },
    clearState() {
      this.data = {
        name: '',
        date: '',
      }
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
      })
    },
  },
  created() {
    if (this.formMode === 'edit') {
      this.initialData.date = new Date(this.initialData.date)
      this.data = { ...this.initialDate }
    }
  },
}
</script>
