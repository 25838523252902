<template>
  <b-modal
    :active="isModalOpen"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    :on-cancel="onCloseModalCallback"
    aria-role="dialog"
    aria-modal
    width="600px"
  >
    <template>
      <div class="modal-card">
        <section class="modal-card-body">
          <header>
            <div class="columns">
              <div class="column">
                <h4 class="title has-text-weight-bold">{{ titleModal }}</h4>
              </div>
              <div class="column is-2">
                <span
                  @click="onCloseModalCallback"
                  class="pointer-click is-pulled-right"
                >
                  Close
                </span>
              </div>
            </div>
          </header>
          <slot name="content"></slot>
        </section>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    onCloseModalCallback: Function,
    titleModal: String,
  },
  data() {
    return {
      isComponentModalActive: false,
    }
  },
}
</script>
