<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Calendar Settings</h2>
      <r-third-level-menu></r-third-level-menu>
    </template>
    <template #page-content>
      <div class="component">
        <SettingGlobalTimeoff />
      </div>
    </template>
  </r-page>
</template>

<script>
import SettingGlobalTimeoff from '@/views/Settings/CalendarSettings/GlobalTimeoffSetting'

export default {
  components: {
    SettingGlobalTimeoff,
  },
}
</script>
