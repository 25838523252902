<template>
  <r-page>
    <template #page-header>
      <h3 class="title has-text-weight-bold">Global Time Off</h3>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <b-button class="link" @click="showAddModal">Add</b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div v-if="!isLoading">
        <div class="settings-container">
          <div
            class="row is-flex myDiv"
            v-for="(globalTimeoff, index) in globalTimeoffs"
            :key="index"
          >
            <p class="settings-label">
              <span class="settings-label_name">{{ globalTimeoff.name }}</span>
            </p>
            <div class="paid-days">
              <b-tag>{{ globalTimeoff.isPaid ? 'paid' : 'not paid' }}</b-tag>
            </div>
            <div class="paid-days">
              <div>{{ parseDate(globalTimeoff.date) }}</div>
            </div>
            <div class="edit-container">
              <div class="edit pointer-click" @click="showEditModal(index)">
                Edit
              </div>
            </div>
          </div>
        </div>

        <ModalGlobalTimeoff
          :title-modal="titleModal"
          :is-modal-open="isModalOpen"
          :on-close-modal-callback="closeModal"
        >
          <template v-slot:content>
            <FormGlobalTimeoff
              :form-mode="formMode"
              :initial-data="selected"
              v-on:close-modal="closeModal"
            ></FormGlobalTimeoff>
          </template>
        </ModalGlobalTimeoff>
      </div>
      <div class="loading-department" v-else>
        <Loading :is-loading="isLoading" />
      </div>
    </template>
  </r-page>
</template>

<script>
import Loading from '@/components/Loading'
import { showToast } from '@/services/util'
import { mapActions, mapGetters } from 'vuex'
import ModalGlobalTimeoff from '@/components/ModalGlobalTimeoff'
import FormGlobalTimeoff from '@/views/Settings/CalendarSettings/FormGlobalTimeoff'
import moment from 'moment'

export default {
  components: {
    Loading,
    ModalGlobalTimeoff,
    FormGlobalTimeoff,
  },
  computed: {
    ...mapGetters({
      globalTimeoffs: 'settings/getGlobalTimeoffs',
    }),
  },
  data() {
    return {
      isLoading: false,
      titleModal: '',
      selected: null,
      formMode: '',
      isModalOpen: false,
    }
  },
  methods: {
    ...mapActions({
      fetchGlobalTimeoffs: 'settings/fetchGlobalTimeoffs',
    }),
    parseDate(timeStamp) {
      return moment(timeStamp).format('DD MMM YYYY')
    },
    closeModal() {
      this.isModalOpen = false
      this.selected = null
    },
    showEditModal(index) {
      this.selected = this.globalTimeoffs[index]
      this.titleModal = 'Edit Global Timeoff'
      this.formMode = 'edit'
      this.isModalOpen = true
    },
    showAddModal() {
      this.titleModal = 'Add Global Timeoff'
      this.formMode = 'add'
      this.selected = null
      this.isModalOpen = true
    },
    async loadGlobalTimeoffs() {
      this.isLoading = true
      try {
        await this.fetchGlobalTimeoffs()
      } catch (err) {
        showToast(err.response.data.message, 'is-danger', 'is-bottom')
      }
      this.isLoading = false
    },
  },
  async created() {
    await this.loadGlobalTimeoffs()
  },
}
</script>
